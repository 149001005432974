<template>
  <div :class="parentClass || 'js-common-wrap'">
    <div class="js-common-head">
      <label v-if="type === 'edit'">标签管理</label>
    </div>
    <div class="js-common-content tree-wrap">
      <div class="submit-btns">
        <el-button size="small" @click.stop="append" v-if="type === 'edit'"
          >添加标签</el-button
        >
        <!-- <el-button type="primary" size="small" @click.stop="save"
          >保存</el-button
        > -->
      </div>
      <el-tree
        ref="tagsTree"
        :data="treeData"
        node-key="id"
        :default-expand-all="false"
        :expand-on-click-node="false"
        :check-on-click-node="true"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <div class="edit-status" v-if="type === 'edit'">
            <span class="custom-show" v-if="!data.isEdit">{{
              node.label || "-"
            }}</span>
            <span class="custom" v-if="data.isEdit"
              ><el-input
                size="small"
                class="custom-input"
                placeholder="请输入内容"
                :value="node.label"
                @input="(e) => input(e, node, data)"
              >
                <el-button-group slot="append">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="reset(node, data, false)"
                    >取消</el-button
                  ><el-button size="mini" @click="edit(node, data, false)"
                    >保存</el-button
                  >
                </el-button-group>
              </el-input></span
            >
            <span class="btns-control">
              <el-button
                type="text"
                size="mini"
                @click="() => append(node, data)"
                v-if="node.level < 3"
              >
                增加
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="() => edit(node, data, true)"
                v-if="type === 'edit'"
              >
                编辑
              </el-button>
              <el-popconfirm
                style="margin-left: 10px"
                icon="el-icon-info"
                icon-color="red"
                title="确定删除节点吗？"
                @confirm="remove(node, data)"
                v-if="type === 'edit'"
              >
                <el-button size="mini" type="text" slot="reference"
                  >删除</el-button
                >
              </el-popconfirm>
            </span>
          </div>
          <div class="selecte-status" v-if="type === 'select'">
            <el-checkbox
              v-model="data.checked"
              @change="(e) => change(e, node, data)"
            ></el-checkbox>
            <span class="custom-select">{{ node.label || "-" }}</span>
          </div>
        </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "parentClass"], // edit 编辑状态为角色权限设置；select  选择状态，不能添加编辑
  data() {
    return {
      treeData: [
        // {
        //   id: 1,
        //   label: "一级 1",
        //   isEdit: false,
        //   children: [
        //     {
        //       id: 4,
        //       label: "二级 1-1",
        //       isEdit: false,
        //       children: [
        //         {
        //           id: 9,
        //           label: "三级 1-1-1",
        //           isEdit: false,
        //         },
        //         {
        //           id: 10,
        //           label: "三级 1-1-2",
        //           isEdit: false,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      tempraryStore: {},
      fatherArr: [],
    };
  },

  methods: {
    change(checked, node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      // 选中的时候，清空所有
      if (checked) {
        this.resetAllTreeChecked(this.treeData);
        const needStr = [data.label, ...this.findFather(node)];
        this.$emit("check", needStr);
      }

      data.checked = checked;

      children.splice(index, 1, data);

      // this.$emit("check", includeHalfChecked, this);
    },
    input(value, node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);

      data.label = value;
      children.splice(index, 1, data);
    },

    async append(node, data) {
      const grandId = 0;
      let newChild = {
        id: grandId,
        label: "",
        children: [],
        isEdit: true,
      };

      // 新增
      const parentId = data && node ? data.id : grandId;

      const tempraryNode = {
        name: newChild.label,
        parentId,
      };

      newChild.id = await this.addNode(tempraryNode);

      //   if (!data.children) {
      //     this.$set(treeData, "children", []);
      //   }
      if (data && node) {
        data.children.push(newChild);
      } else {
        this.treeData.push(newChild);
      }
    },

    async remove(node, data) {
      const parent = node.parent;
      let pass = true;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);

      pass = await this.removeNode(data.id);

      pass && children.splice(index, 1);
    },
    reset(node, data, status) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);

      data.isEdit = status;
      data.label = this.tempraryStore[data.id];
      this.tempraryStore[data.id] = "";
      console.log(this.tempraryStore, data.id);

      children.splice(index, 1, data);
    },
    // 编辑、新建方法，
    async edit(node, data, status, skipVetify) {
      // 点编辑的时候，记录一下原始值
      let pass = true;
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      // 编辑状态，保存原始文案
      if (status) {
        this.tempraryStore[data.id] = data.label;
      }
      console.log("1");

      // 关闭状态走请求
      if (!status) {
        console.log("2");

        // 为空拦截
        if (!data.label) {
          this.$message({
            type: "error",
            message: "文字不能为空",
            duration: 2000,
          });
          return false;
        }
        console.log("3");

        // 文案相同，关闭状态
        if (this.tempraryStore[data.id] === data.label) {
          data.isEdit = status;
          children.splice(index, 1, data);
          return false;
        }
        console.log("4");

        console.log(this.tempraryStore[data.id], data.label);

        const tempraryNode = {
          name: data.label,
          parentId: node.parent.level ? node.parent.data.id : 0,
        };
        pass = await this.modifyNode(tempraryNode, node.data.id);
      }
      console.log(pass);
      // 走了异步验证，没返回成功则不添加，且编辑状态不改变
      if (pass) {
        data.isEdit = status;
        children.splice(index, 1, data);
      }
    },
    save() {
      console.log(this.data);
    },
    getTreeLeavesNode() {
      this.$axios({
        method: "get",
        url: "api/csp/mgr/v1/tag/tree",
      }).then(({ data }) => {
        console.log(data);
        if (!data.status) {
          this.treeData = this.formatData(data.data);
          console.log(this.treeData);
        }
      });
    },
    formatData(array) {
      return array.map((item) => {
        if (Array.isArray(item.tagList) && item.tagList.length) {
          item.children = this.formatData(item.tagList);
        } else {
          item.children = [];
        }

        return {
          ...item,
          label: item.name,
          isEdit: false,
          checked: false,
        };
      });
    },
    resetAllTreeChecked(arr) {
      arr.forEach((item) => {
        if (item.checked) {
          item.checked = false;
        }

        if (Array.isArray(item.children) && item.children.length) {
          this.resetAllTreeChecked(item.children);
        }
      });
    },
    findFather(node) {
      let result = [];
      if (node.parent && node.parent.level > 0) {
        result.push(node.parent.data.label);
        return result.concat(this.findFather(node.parent));
      }
      return result;
    },
    addNode(node) {
      return new Promise((resovle, reject) => {
        this.$axios({
          method: "post",
          url: "api/csp/mgr/v1/tag/",
          data: {
            data: node,
          },
        })
          .then(({ data }) => {
            if (data.status === 0) {
              resovle(data.data);
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 2000,
              });
              reject(data.msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    modifyNode(node, id) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "put",
          url: `api/csp/mgr/v1/tag/${id}`,
          data: {
            data: node,
          },
        })
          .then(({ data }) => {
            if (data.status === 0) {
              resolve(true);
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 2000,
              });
              resolve(false);
            }
          })
          .catch((e) => {
            reject();
          });
      });
    },
    removeNode(id) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "delete",
          url: `api/csp/mgr/v1/tag/${id}`,
        }).then(({ data }) => {
          if (data.status === 0) {
            this.getTreeLeavesNode();
            resolve(true);
          } else {
            this.$message({
              type: "error",
              message: data.msg,
              duration: 2000,
            });
            resolve(false);
          }
        });
      });
    },
  },
  mounted() {
    this.getTreeLeavesNode();
  },
};
</script>

<style lang="scss">
.js-common-content {
  background: #fff;
}
.tree-wrap {
  padding: 20px 50px;
  .custom {
    padding: 10px;
  }
  .custom-show {
    display: inline-block;
  }
  .custom-input {
    width: 220px;
  }
  .custom-tree-node {
  }
  .btns-control {
    padding-left: 50px;
  }
  .selecte-status {
    display: flex;
    align-items: center;
    .custom-select {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}
.el-tree-node__content {
  height: auto !important;
}
.submit-btns {
  padding: 30px 0px;
}
.el-button-group {
  display: flex;
  .el-button:nth-child(2) {
    border-left: 1px solid #ccc;
  }
}
</style>
