import { render, staticRenderFns } from "./VideoTagsManage.vue?vue&type=template&id=3580dc38"
import script from "./VideoTagsManage.vue?vue&type=script&lang=js"
export * from "./VideoTagsManage.vue?vue&type=script&lang=js"
import style0 from "./VideoTagsManage.vue?vue&type=style&index=0&id=3580dc38&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oliver/Documents/jiashi/jiashi1.0/node_modules/.store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3580dc38')) {
      api.createRecord('3580dc38', component.options)
    } else {
      api.reload('3580dc38', component.options)
    }
    module.hot.accept("./VideoTagsManage.vue?vue&type=template&id=3580dc38", function () {
      api.rerender('3580dc38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AddTags/VideoTagsManage.vue"
export default component.exports