var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.parentClass || "js-common-wrap" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _vm.type === "edit" ? _c("label", [_vm._v("标签管理")]) : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "js-common-content tree-wrap" },
      [
        _c(
          "div",
          { staticClass: "submit-btns" },
          [
            _vm.type === "edit"
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.append.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("添加标签")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("el-tree", {
          ref: "tagsTree",
          attrs: {
            data: _vm.treeData,
            "node-key": "id",
            "default-expand-all": false,
            "expand-on-click-node": false,
            "check-on-click-node": true,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ node, data }) {
                return _c("span", { staticClass: "custom-tree-node" }, [
                  _vm.type === "edit"
                    ? _c("div", { staticClass: "edit-status" }, [
                        !data.isEdit
                          ? _c("span", { staticClass: "custom-show" }, [
                              _vm._v(_vm._s(node.label || "-")),
                            ])
                          : _vm._e(),
                        data.isEdit
                          ? _c(
                              "span",
                              { staticClass: "custom" },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "custom-input",
                                    attrs: {
                                      size: "small",
                                      placeholder: "请输入内容",
                                      value: node.label,
                                    },
                                    on: {
                                      input: (e) => _vm.input(e, node, data),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button-group",
                                      {
                                        attrs: { slot: "append" },
                                        slot: "append",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.reset(
                                                  node,
                                                  data,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "mini" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.edit(
                                                  node,
                                                  data,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("保存")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          { staticClass: "btns-control" },
                          [
                            node.level < 3
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: { click: () => _vm.append(node, data) },
                                  },
                                  [_vm._v(" 增加 ")]
                                )
                              : _vm._e(),
                            _vm.type === "edit"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: () => _vm.edit(node, data, true),
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            _vm.type === "edit"
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      icon: "el-icon-info",
                                      "icon-color": "red",
                                      title: "确定删除节点吗？",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.remove(node, data)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          size: "mini",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.type === "select"
                    ? _c(
                        "div",
                        { staticClass: "selecte-status" },
                        [
                          _c("el-checkbox", {
                            on: { change: (e) => _vm.change(e, node, data) },
                            model: {
                              value: data.checked,
                              callback: function ($$v) {
                                _vm.$set(data, "checked", $$v)
                              },
                              expression: "data.checked",
                            },
                          }),
                          _c("span", { staticClass: "custom-select" }, [
                            _vm._v(_vm._s(node.label || "-")),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }